import React, { useState } from "react"
import { Link } from "gatsby"

const Navigation2 = ({}) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const [isShown, toggleShown] = useState(false)
  const [isLegal, toggleLegal] = useState(false)

  return (
    <nav className="w-full flex items-center justify-between flex-wrap bg-white p-3 lg:fixed lg:z-50 lg:top-0 ">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <Link to="/" className="text-black inline-block">
          <img
            src="/images/ebrick-logo.png"
            alt="SLS eBrick"
            className="mt-1 inline-block"
            style={{ maxWidth: "190px" }}
          />
        </Link>
      </div>

      <div className="block lg:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded text-gray-800 border-gray-400 hover:text-black hover:border-black"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block flex-grow lg:flex lg:items-center lg:w-auto nav-links transition duration-400 ease-in-out transform`}
      >
        <div className="lg:flex-grow md:items-center lg:items-center ml-10 pt-3 tracking-tight">
          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5">
            <a href="#que-hacemos" className="hover:underline">
              ¿Qué hacemos?
            </a>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
            <a href="#como-funciona" className="hover:underline">
              ¿Cómo funciona?
            </a>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
            <a href="#quienes-somos" className="hover:underline">
              ¿Quiénes somos?
            </a>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
            <a href="#nuestra-comunidad" className="hover:underline">
              Nuestra Comunidad
            </a>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
            <a href="#contacto" className="hover:underline">
              Contacto
            </a>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
            <a
              href="https://frontend.ebricknetwork.com/slsebrick/register/"
              className="hover:underline"
            >
              Regístrate
            </a>
          </div>

          <div className="mt-4 lg:inline-block lg:mt-0 text-gray-800 text-sm hover:text-black mr-5 ">
            <a
              href="https://services.ebricknetwork.com/"
              className="rounded-full border border-blue-400 px-4 py-1 hover:bg-blue-100"
            >
              Ingresar
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation2
