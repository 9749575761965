import React from "react";
import ReactPlayer from "react-player";
import Contact from "../components/Contact";

import Layout from "../components/layout";
import Nav from "../components/Nav";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="w-full mx-auto">
      <Nav />
    </div>

    <div className="w-full mx-auto relative">
      {/* Header */}
      <div
        className="main_header w-full py-10 lg:pb-40 md:py-12 lg:pt-40 lg:mt-20"
        id="main_header"
      >
        <div className="w-10/12 lg:w-1/2 ml-8 lg:ml-20 md:ml-12 text-white">
          <img
            src="/images/eln-logo-white.png"
            alt="eBrick Logistic Network"
            style={{ maxWidth: "40%" }}
            className="mt-3"
          />
          <h2 className="text-white text-3xl">
            Forma parte de una red verificada
          </h2>
          <p className="my-8 text-white ">
            En eBrick Network conocerás emprendedores, talentos creativos y
            propietarios de pequeñas tiendas; verificados, que buscan
            transformar y monetizar su modelo tradicional.
          </p>
          <a
            href="https://frontend.ebricknetwork.com/slsebrick/register/"
            className="bg-blue-900 rounded-full py-3 px-5 shadow-sm"
          >
            Conéctate con nosotros
          </a>
        </div>
      </div>
      {/* top header */}

      {/*Que hacemos */}

      <div className="que_hacemos w-full mx-auto" id="que-hacemos">
        <div className="w-10/12 mx-auto pt-10 lg:pt-16 md:pt-10 pb-10">
          <h3 className="mx-auto text-center text-teal-500 text-3xl">
            ¿Qué hacemos?
          </h3>
          <h5 className="text-md md:text-lg mx-auto text-center mt-3 lg:mt-4 md:mt-3">
            Conectar a el emprendedor que busca talento freelance, y el
            freelancer que está buscando aumentar sus ingresos.
          </h5>

          <div className="w-full lg:flex mx-auto mt-8">
            <div className="w-full lg:w-1/3 mt-8 text-center">
              <img src="/images/red.png" alt="Network" className="mx-auto" />
              <h4 className="text-teal-500 mt-6 mb-4">Registramos</h4>
              <p>Quién eres, que haces y que necesitas.</p>
            </div>
            <div className="w-full lg:w-1/3 mt-8 mx-auto text-center">
              <img src="/images/pig.png" alt="Save money" className="mx-auto" />
              <h4 className="text-teal-500 mt-6 mb-4">Verificamos</h4>
              <p>Eres quien dices ser, haces lo que dices hacer.</p>
            </div>
            <div className="w-full lg:w-1/3 mt-8 mx-auto text-center">
              <img src="/images/cash.png" alt="Earn cash" className="mx-auto" />
              <h4 className="text-teal-500 mt-6 mb-4">Conectamos</h4>
              <p>Las necesidades dentro de el eBrick Network</p>
            </div>
          </div>

          <div className="mx-auto w-full lg:w-8/12 my-12 lg:mt-20 text-center">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url="/video/vid.mp4"
                width="100%"
                height="100%"
                controls="true"
              />
            </div>
          </div>
        </div>
      </div>

      {/*/ que hacemos */}

      {/*banner 1*/}

      <div
        className="w-full bg-blue-900"
        style={{
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgrounSize: "cover",
          paddingTop: "40px",
          paddingBottom: "40px"
        }}
        id="sitting"
      >
        <div className=" w-full lg:w-10/12 mx-auto p-3">
          <div className="w-full lg:w-2/3">
            <img
              src="/images/eln-logo-white.png"
              alt="eBrick Logistic Network"
              style={{ maxWidth: "40%" }}
              className="mt-3"
            />
            <p className="text-white">
              Mejora tu emprendimiento en nuestra red, conéctate con miles de
              freelancers en todo el mundo y desarrolla tu negocio de forma
              rápida y sencilla.
            </p>
          </div>
        </div>
      </div>
      {/*/ banner 1 */}

      {/*Como funciona */}

      <div className="w-full bg-gray-200" id="como-funciona">
        <div className="w-full lg:w-10/12 mx-auto py-16">
          <h3 className="mx-auto text-center text-teal-500 text-3xl">
            ¿Cómo funciona?
          </h3>

          <div className="w-full lg:flex">
            <div className="w-full lg:w-1/3 mx-auto text-center py-10 px-5">
              <h4 className="text-teal-500 mt-6 mb-4">Regístrate</h4>
              <img src="/images/laptop.png" alt="Tech" className="mx-auto" />
              <p className="text-sm mt-5">
                Regístrate gratis y crea tu perfil profesional con el que podes
                ofrecer tus servicios al resto del mundo
              </p>
            </div>

            <div className="w-full lg:w-1/3 mx-auto text-center py-10 px-5">
              <h4 className="text-teal-500 mt-6 mb-4">Sube tu portafolio</h4>
              <img src="/images/browser.png" alt="Tech" className="mx-auto" />
              <p className="text-sm mt-5">
                Comparte tus mejores proyectos y tu portafolio para atraer
                nuevos clientes. ¡La red se encarga de conectarlos!
              </p>
            </div>

            <div className="w-full lg:w-1/3 mx-auto text-center py-10 px-5">
              <h4 className="text-teal-500 mt-6 mb-4">
                Sé contactado por tus clientes
              </h4>
              <img src="/images/chat.png" alt="Tech" className="mx-auto" />
              <p className="text-sm mt-5">
                Sé contratado en cualquier parte del mundo y usa nuestra
                plataforma para discutir los detalles con tus clientes ¡Manténte
                en contacto!
              </p>
            </div>
          </div>
          <div className="mx-auto text-center">
            <a
              href="https://frontend.ebricknetwork.com/slsebrick/register/"
              className="rounded-full bg-blue-900 text-white px-8 py-2"
            >
              Registrate
            </a>
          </div>
        </div>
      </div>

      {/*/ Como funciona */}

      {/*Quienes Somos */}

      <div className="w-full mx-auto" id="quienes-somos">
        <div className="w-10/12 mx-auto lg:flex py-16">
          <div className="w-full lg:w-1/2">
            <img src="/images/workers.png" alt="Sharing work" />
          </div>

          <div className="w-full lg:w-1/2 px-2 lg:px-10 pt-10 lg:pt-0">
            <p className="italic text-gray-600">
              Únete a nuestra comunidad eBrick Network
            </p>
            <h3 className="mx-auto text-teal-500 text-3xl">¿Quiénes somos?</h3>
            <p className="text-gray-700 py-5">
              SLS Panamá se ha especializado en utilizar la tecnología como un
              facilitador para la gente, brindando soluciones que hacen que tu
              vida sea más sencilla.
            </p>
            <p className="text-gray-700 py-5">
              SLS Panama ha desarrollado una herramienta para la verificación y
              autenticación de identidad para evaluar las confiabilidad de los
              participantes.
            </p>
            <p className="text-gray-700 py-5">
              Nuestros usuarios son quién dicen ser., están donde dicen estar y
              hacen lo que dicen hacer.
            </p>
            <p className="text-gray-700 py-5">
              En SLS Te podemos brindar el manejo de tu red de datos, hosting,
              desarrollado back end administrativo y mucho mas.
            </p>

            <div className="mx-auto text-center mt-10">
              <a
                href="https://slspanama.com"
                className="rounded-full bg-blue-900 text-white px-8 py-2"
              >
                Conocer Más
              </a>
            </div>
          </div>
        </div>
      </div>

      {/*Quienes Somos */}

      {/*banner 2*/}

      <div
        className="w-full bg-blue-900"
        style={{
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "40px",
          paddingBottom: "40px"
        }}
        id="banner2"
      >
        <div className=" w-full lg:w-10/12 mx-auto p-3">
          <div className="w-full lg:w-2/3">
            <img
              src="/images/eln-logo-white.png"
              alt="eBrick Logistic Network"
              style={{ maxWidth: "40%" }}
              className="mt-3"
            />
            <p className="text-white">
              Pon tu talento como freelancer al alcance de miles de
              emprendedores. Ayuda a construir tu sueño, construyendo el de
              otro.
            </p>
          </div>
        </div>
      </div>
      {/*/ banner 2 */}

      {/*Servicios Profesionales */}

      <div className="que_hacemos w-full mx-auto" id="nuestra-comunidad">
        <div className="w-10/12 mx-auto pt-10 lg:pt-16 md:pt-10 pb-10">
          <h3 className="mx-auto text-center text-teal-500 text-3xl">
            Servicios Profesionales más usados
          </h3>

          <div className="w-full lg:flex mx-auto mt-8">
            <div className="w-full lg:1/3 block mx-3 mb-4 lg:mb-0">
              <div
                className="w-full rounded-lg p-6"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "450px"
                }}
                id="phone"
              >
                <h2 className=" text-white italic text-lg">
                  Mejora tus redes sociales
                </h2>

                <h3 className="text-2xl text-white italic">
                  Social Media Manager
                </h3>
              </div>
            </div>

            <div className="w-full lg:1/3 block mx-3 mb-4 lg:mb-0">
              <div
                className="w-full rounded-lg p-6"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "450px"
                }}
                id="web"
              >
                <h2 className=" text-white italic text-lg">
                  Crea tu sitio web
                </h2>

                <h3 className="text-2xl text-white italic">Desarrollo Web</h3>
              </div>
            </div>

            <div className="w-full lg:1/3 block mx-3 mb-4 lg:mb-0">
              <div
                className="w-full rounded-lg p-6"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "450px"
                }}
                id="ipad"
              >
                <h2 className="text-white italic text-lg">
                  Dale identidad a tu marca
                </h2>

                <h3 className="text-2xl text-white italic">Diseño Gráfico</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*/ Servicios profesionales */}

      {/*contact form*/}

      <Contact />

      {/*/contact form*/}

      {/*Footer*/}

      <div className="w-full bg-blue-800 py-16">
        <div className="w-10/12 mx-auto text-white lg:flex">
          <div className="w-full lg:w-1/3 text-sm px-4 mb-5">
            <img
              src="images/eln-logo-white.png"
              alt="eBrick Logistic Network"
              style={{ maxWidth: "80%" }}
              className="mt-3"
            />
            <p className="mt-3 text-white">
              Forma parte de la comunidad que te hace crecer.
            </p>
            <p className="mt-2 text-white">
              &copy; Copyright 2020 eBrick, Derechos Reservados.
            </p>
          </div>
          <div className="w-full lg:w-1/3 text-sm px-4 mb-4">
            <h2 className="text-2xl">Menu</h2>
            <ul className="mt-5">
              <li className="mt-2">
                <a href="#que-hacemos">¿Qué hacemos?</a>
              </li>
              <li className="mt-2">
                <a href="#como-funciona">¿Cómo funciona?</a>
              </li>
              <li className="mt-2">
                <a href="#quienes-somos">¿Quiénes somos?</a>
              </li>
              <li className="mt-2">
                <a href="#nuestra-comunidad">Nuestra Comunidad</a>
              </li>
              <li className="mt-2">
                <a href="#contacto">Contacto</a>
              </li>
              <li className="mt-2">
                <a href="https://frontend.ebricknetwork.com/slsebrick/register/">
                  Regístrate
                </a>
              </li>
              <li className="mt-2">
                <a href="/20201201-Terminos-Condiciones.pdf">
                  Términos y Condiciones
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/3 text-sm px-4">
            <h2 className="text-2xl">Contacto</h2>
            <p className="mt-3 text-white">Tel: +507 8337407</p>
            <p className="mt-3 text-white">Email: support@slsebrick.com</p>
            <p className="mt-3 text-white">
              Dirección: Ave. Samuel Lewis, Obarrio, 56 st., Panamá Design
              Center, 13th Floor, Office 13F, Panama City, Panama
            </p>
          </div>
        </div>
      </div>

      {/*Footer*/}
    </div>
  </Layout>
);

export default IndexPage;
